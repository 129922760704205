body.kit {
  background: url("../images/NG16-GOT_48 CO.jpg") no-repeat top center fixed;
  background-size: auto 180vh;
}
 #kit-landing {
  #landingIntro {
  	margin-top: 170px;
  	@media (orientation: landscape) {
	  	margin-top: 160px;
	  	h3 {
		  	margin-top: 5px;
		  	margin-bottom: 5px;
	  	}
  	}
  	@include at-breakpoint ('medium') {
	  margin-top: 30vh;
  	}
  	
  }
  background:transparent;
  height: 100vh;
  position:relative;
  overflow: hidden;
  
  .inner {
  	height: 100%;
    overflow: hidden;
    width: 100%;
    background: rgba($color-tertiary,0.5);
  }
  
  .triangle-topleft {
	  display: block;
	  position: absolute;
	  z-index: 9;
	  background: url("../images/appelprojets-small.png") no-repeat;
	  min-width: 330px;
	  min-height: 165px;
	  @include at-breakpoint ('small') {
		background: url("../images/appelprojets-small.png") no-repeat;
		min-width: 330px;
		min-height: 165px;
	  }
	  @include at-breakpoint ('medium') {
		@media (orientation: landscape) {
			background: url("../images/appelprojets-small.png") no-repeat;
			min-width: 330px;
			min-height: 165px;
		}
		@media (orientation: portrait) {
			background: url("../images/appelprojets.png") no-repeat;
	        min-width: 660px;
			min-height: 330px;
		}
	  }
	  @include at-breakpoint ('large') {
		  background: url("../images/appelprojets.png") no-repeat;
	      min-width: 660px;
	      min-height: 330px;
	  }
    }
    
    .triangle-topleft-en {
	  display: block;
	  position: absolute;
	  z-index: 9;
	  background: url("../images/appelprojets-small_en.png") no-repeat;
	  min-width: 330px;
	  min-height: 165px;
	  @include at-breakpoint ('small') {
		background: url("../images/appelprojets-small_en.png") no-repeat;
		min-width: 330px;
		min-height: 165px;
	  }
	  @include at-breakpoint ('medium') {
		@media (orientation: landscape) {
			background: url("../images/appelprojets-small_en.png") no-repeat;
			min-width: 330px;
			min-height: 165px;
		}
		@media (orientation: portrait) {
			background: url("../images/appelprojets_en.png") no-repeat;
	        min-width: 660px;
			min-height: 330px;
		}
	  }
	  @include at-breakpoint ('large') {
		  background: url("../images/appelprojets_en.png") no-repeat;
	      min-width: 660px;
	      min-height: 330px;
	  }
    }
  }