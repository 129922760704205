body {
  margin: 0;
  padding: 0;
}

section {
  overflow:hidden;
}

.small-container {
  width: 86%;
  margin: 0 auto;
}
.only-medium {
  display:none;
}
.only-mobile {
  display:block;
}
@include at-breakpoint ('medium') {
  .only-mobile {
    display:none;
  }
  .only-medium {
    display:block;
  }
  .small-container {
    width: 100%;
  }

  .container {
    max-width: 1200px;
    width: 86%;
    margin: 0 auto;
  }

  .left,
  .right {
    width: 45%;
  }

  .left {
    float: left;
    padding-right: 5%;
  }

  .right {
    float: right;
    padding-left: 5%;
  }
}
