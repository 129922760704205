#kit-specification {
  background: $color-tertiary;
  position: relative;
  z-index: 1;
  margin: 0 0 0px;
  padding: 30px 0;
  overflow: visible;
  @include angle(both, true);

  .cols {
	padding-top: 30px;
    padding-bottom: 40px;
  }
}