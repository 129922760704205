#kit-conseil {
  position: relative;
  padding: 80px 0;
  background: white;
  .thumb {
	width: 100%;
	display: inline-block;
	  @include at-breakpoint ('medium') {
		width: 49%;
      }
      @include at-breakpoint ('small') {
	     width: 49%;
      }
  }


}