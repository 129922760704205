section {
  background:white;
}

// mixin
@mixin angle($pseudo, $flip: false, $angle: 1.5deg) {
  // Possible values for $pseudo are: before, after, both
  @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
    position: relative;
    z-index: 1;
    $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

    #{$selector} {
      background: inherit;
      content: '';
      display: block;
      height: 50%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    @if $pseudo == 'before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo == 'after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 100%;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }

    @if $pseudo == 'both' {
      &:before {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }

      &:after {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }
  }
}

// slanted edges here
.edge--bottom {
  @include angle(after);
}

.edge--bottom--reverse {
  @include angle(after, true);
}

.edge--top {
  @include angle(before);
}

.edge--top--reverse {
  @include angle(before, true);
}

.edge--both {
  @include angle(both);
}

.edge--both--reverse {
  @include angle(both, true);
}

// demo code below
.block {
  color: #fff;
  font-family: 'Fira Sans', sans-serif;
  margin: 0 0 200px;
  padding: 20% 20px;
  text-align: center;
}


button,.button {
  text-decoration: none;
  cursor:pointer;
  background: transparent;
  border: 2px solid $color-primary;
  color: $color-primary;
  font-weight: bold;
  padding: 0.5em;
  font-size: 1.2em;
  @media screen and (max-width: 960px) {
    font-size: 1.1em;
  }
  @media screen and (max-width: 480px) {
    font-size: 1em;
  }

  a {
    text-decoration: none;
    display: block;
  }

  &:hover {
  }

  &:active {
    opacity: 1;
  }
}

.bg-primary-color {
  background-color: $color-primary;
}

.negative {
  * {
    border-color: white;
    color: white;
    input, textarea {
		color: black;
		border: black;
	}
  }
  
}
#language {
	position: absolute;
    margin: 10px;
    right: 0px;
    z-index: 100;
}
// Forms
form {
  .form-row {
    clear: both;
    margin: 2vh 0;
  }
  .fifty {
	  overflow: hidden;
	  label {
		width: 50%;
		float: left;
	    box-sizing: border-box;
	  }
  }
  
  .align-right {
	  text-align: right;
  }

  .label-text {
    float: left;
    width: auto;
    margin-right: 5%;
  }
  @include at-breakpoint('small') {
	  .label-text {
	    width: 20%;
	  }
  }

  input,
  textarea {
    border: 2px solid #555;
    padding: 0.5em;
    box-sizing: border-box;
    width: 100%;
    @include at-breakpoint('small') {
	    width: 75%;
    }

    &:focus {
      border-color: $color-secondary;
    }
  }
}

.tabs {
  margin:1em 0;padding:0;
    .tab {
      display:inline-block;
      .tablink {
        display:block;
        font-size: 1.2em;
        padding: 0.5em;
        font-weight: bold;
        text-decoration:none;
      }
    }
}
