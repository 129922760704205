#intro {
  position: relative;
  padding: 0;
  overflow:hidden;

  .intro-part {
    &.part-1 {
      background: $color-secondary;
      position: relative;
      padding-top: 2em;
      margin-bottom: 3em;

      p {
        padding: 10px 0;
      }

      p.paraA {
        font-size: 1.5em;
        @include at-breakpoint('medium') {
          font-size: 2.2em;
        }
        @include at-breakpoint('large') {
          font-size: 2.7em;
        }
        text-transform: uppercase;
      }

      p.paraB {
        font-size: 1.2em;
        width: 90%;
        padding-bottom: 40px;
        @include at-breakpoint('medium') {
        	width: 70%;
        	font-size: 1.4em;
        }
        @include at-breakpoint('large') {
			width: 800px;
        	font-size: 1.4em;
        }
      }
      // triangle
      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 3em 0 0 100vw;
        border-color: $color-secondary transparent transparent transparent;
        margin-bottom: 3em;
        overflow: hidden;
        @include at-breakpoint('large') {
          border-top-width: 7em;
        }
      }
	}
  }
  .cols {
	padding-top: 30px;
    padding-bottom: 40px;
  }

  @include at-breakpoint ('large') {
	.col-part-2 {
		padding-top: 55px;
	}
    .cols {
      margin-top: 80px;
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      -webkit-column-gap: 2vw;
      -moz-column-gap: 2vw;
      column-gap: 4vw;

      p {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        margin-top:0;
      }
    }
  }
}

#rules {
  background-color: $color-secondary;
  padding: 1em 0;
  margin-top: 5em;
  z-index: 32;
  position: relative;

  &:after,
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
  }

  &:before {
    top: -3em;
    border-style: solid;
    border-width: 0 0 3em 100vw;
    border-color: transparent transparent $color-secondary transparent;
  }

  &:after {
    bottom: -3em;
    border-style: solid;
    border-width: 3em 0 0 100vw;
    border-color: $color-secondary transparent transparent transparent;
  }

  @include at-breakpoint ('medium') {
    &:after,
    &:before {
      display: none;
    }
    background: transparent;
    position: absolute;
    display: block;
    width: 0;
    left: 0;
    height: 0;
    bottom: 0;
    border-bottom: 100vh solid $color-secondary;
    border-right: 80vw solid transparent;
    z-index: -1;

    .rules-inner {
      width: 100vw;
      position: absolute;
      margin-top: 50vh;
      @media screen and (max-height: 760px) {
        margin-top: 40vh;
      }
    }

    .left {
      width: 50%;
      margin: 0;
      padding: 0;
    }
  }
}
