@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),  url('../fonts/Montserrat-ExtraLight.otf')  format('opentype'),
	   url('../fonts/Montserrat-ExtraLight.woff') format('woff'), url('../fonts/Montserrat-ExtraLight.ttf')  format('truetype'), url('../fonts/Montserrat-ExtraLight.svg#Montserrat-ExtraLight') format('svg');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),  url('../fonts/Montserrat-SemiBold.otf')  format('opentype'),
	   url('../fonts/Montserrat-SemiBold.woff') format('woff'), url('../fonts/Montserrat-SemiBold.ttf')  format('truetype'), url('../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/Montserrat-Regular.otf')  format('opentype'),
	     url('../fonts/Montserrat-Regular.woff') format('woff'), url('../fonts/Montserrat-Regular.ttf')  format('truetype'), url('../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: 500;
  font-style: normal;
}
