/*
 * Typography
 */
body {
  font-family: 'Montserrat', sans-serif;
  color: $color-tertiary;
}
a {
   outline: 0;
   color: black;
}
p {
  line-height: 1.5;
  font-weight: 100;
}
ul {
	li {
		font-weight: 100;
		line-height: 1.5;
		padding-left: 10px;
	}
}

b,
strong {
  font-weight: 500;
}

h1,
h2,
h3,
h4 {
  text-transform: uppercase;
}

li {
  line-height: 1.5;
}

h2 {
  font-weight: 500;
  font-size: 1.7em;
}
.centered {
  text-align:center;
}

.light {
  font-weight: 100;
}
.red {
	color: $color-secondary !important;
}