// bower:scss
// endbower
@import 'variables';
@import 'layout';
@import 'loadfonts';
@import 'typography';
@import 'reusable';
@import 'section_landing';
@import 'section_intro';
@import 'section_details';
@import 'section_participer';
@import 'section_apropos';
@import 'section_apercu';
@import 'section_kit-intro';
@import 'section_kit-projection';
@import 'section_kit-specification';
@import 'section_kit-conseil';
@import 'section_kit-scene';
@import 'section_kit-modele';
@import 'section_kit-landing';
@import 'section_kit-footer';

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: $color-tertiary;
  padding: 0.2em 0;
}
