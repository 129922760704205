/*
 *  A PROPOS
 */
#a-propos {
  position: relative;
  z-index:1;
  background:white;


  @include at-breakpoint ('medium') {
    .container {
      position:relative;
      height: 100%;
    }
    a.mei-logo {
      top: -1.8em;
      right: -1em;
    }
    height: 450px;

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      left: 0;
      height: 0;
      bottom: 0;
      border-top: 120vh solid $color-tertiary;
      border-right: 80vw solid transparent;
      z-index: 0;
      top: -10vh;
    }
  }

  & > div {
    overflow: hidden;
  }

  #qui-sommes-nous {
    position: relative;
    background: $color-primary;
    button {
	    margin-top: 10px;
    }
    a {
	    text-decoration: none;
    }
    p {
	    a {
		    text-decoration: underline;
	    }
    }
    @include at-breakpoint ('small') {

    }

    @include at-breakpoint ('medium') {
	    width: 50%;
	    .para-1 {
		    width: 100%;
	    }
	    .para-2 {
		    width: 85%;
	    }

    }

    .small-container{
	    padding-bottom: 30px;
    }

    @include at-breakpoint('large') {
	    .small-container{
		    padding-bottom: 0px;
	    }
    }

    a.mei-logo {
      position: absolute;
      right: 1em;
      top: 1em;
      width: 55px;
      height: 55px;
      background: url("../images/mei.png") no-repeat center center / contain;
      display: block;
    }

    h2 {
      padding-top: 1em;
      padding-right: 60px;
      padding-bottom: 1em;
      margin-bottom: 0;
    }

    .small-container {
      overflow: hidden;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -3em;
      border-style: solid;
      border-width: 3em 100vw 0 0;
      border-color: $color-primary transparent transparent transparent;
    }

    @include at-breakpoint ('medium') {
      h2 {
        overflow: hidden;
        position: relative;
        margin-bottom: 2vh;
        padding-right: 111px;
      }
      background: none;

      &:after {
        display: none;
      }
    }
  }

  #contact {

	.section {
		margin-left: 0px;
		margin-top: 60px;
		margin-bottom: 60px;
	}
	#en-edit {
		margin-top: 60px;
	}
    text-align: center;
    margin-top: 12vh;
    h2 {
	    margin-bottom: 0px;
	    text-align: center;
    }
    p {
	    text-align: center;
	    margin-top: 2px;
	    margin-bottom: 5px;
    }
	.lien {
		margin-top: 0px;
		margin-bottom: 20px;
	}
    @include at-breakpoint ('medium') {
      position:absolute;
      margin-top: 0px;
      right:0;
	    text-align: right;
	    .section {
		    margin-left: 40px;
	    }
	    #en-edit {
		  margin-top: 90px;
	  	}
		h2 {
			text-align: right;
		}
		p {
			text-align: right;
		}
		.socials{
		  display: inline-block;
		  text-align: right;
		  overflow: hidden;
		  .social {
			  float: right;
			  margin-left: 15px;
	  	  }
	  }
	  .lien {
		  margin-top: 10px;
		  margin-bottom: 0px;
	  }
    }

    & > p {
      width: 75%;
      text-align: left;
      float: right;
    }
  }

  #gtm_index_contact {
	  float: right;
  }

  .socials{
	  padding-top: 7px;
	  width: 210px;
	  display: inline-block;
	  text-align: center;
	  overflow: hidden;
	  .social {
		  display: inline-block;
		  margin-left: 15px;
  	  }
  }
}
