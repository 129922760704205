// Tabs

#details {
  .inner {
    padding: 5vh 0;
    overflow:hidden;
  }
  position: relative;
  background: rgba($color-tertiary,0.8);

  .tab-content {
    padding: 0;
    position: relative;
    &.one {
	   background: $color-primary; 
	   &:before {
			border-color: transparent transparent $color-primary transparent;
		}
		&:after {
			border-color: $color-primary transparent transparent transparent;
		}
    }
    &.two {
	    background: $color-secondary;
		&:before {
			border-color: transparent transparent $color-secondary transparent;
		}
		&:after {
			border-color: $color-secondary transparent transparent transparent;
		}
    }

    .container {
		min-height: 300px;
		padding: 5vh 0;
    }
    margin-bottom: 3em;

    &:after,
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      height: 0;
    }

    &:before {
      top: -3em;
      border-style: solid;
      border-width: 0 0 3em 100vw;
      
      margin-bottom: 3em;
      overflow: hidden;
    }

    &:after {
      border-style: solid;
      border-width: 3em 0 0 100vw;
      overflow: hidden;
    }
  }

  .tabs {
    position: relative;
    margin-bottom: 10vh;
    z-index: 10;

    .tab {
      .tablink {
        padding: 0.3em;
        font-size: 1em;
        margin-top: 0.5em;
        margin-bottom:0.5em;
        text-transform: uppercase;
        border: 2px solid white;
        background-color: $color-tertiary;
        margin-left: 2vw;
        margin-right: 2vw;
        color: white;

        @include at-breakpoint('small') {
          font-size: 1.2em;
          padding: 0.5em 1em;
          margin-top:0;
          margin-bottom:0;
        }
        &.active.one {
          background-color: $color-primary;
        }
        &.active.two {
          background-color: $color-secondary;
        }
      }
    }
  }

  .wireframe {
  	min-height: 270px;
    background: url("../images/wireframe_3.png") no-repeat center center / contain;
    
    @include at-breakpoint('small') {
	    min-height: 330px;
    }
    
  }

  @include at-breakpoint ('medium') {
    padding: 0;

    .wireframe {
      float: right;
      width: 50%;
    }
  }

  @include at-breakpoint ('large') {
    .details-part {
      width: 45vw;
    }
  }

  .rules {
    font-size: 0.9em;
    //padding: 1em;
    overflow: hidden;
    //background: $color-tertiary;
  }

  .region-buttons {
    .inner {
      padding-top:0;

      button {
        margin-bottom: 1em;
        display:inline-block;
        margin-right: 1em;
        background: $color-secondary;
        border-color:white;
        color:white;
      }
      .button-participate button {
        background: $color-tertiary;
      }
    }
  }
}
