#kit-projection {
  position: relative;
  padding: 20px 0;
  background: $color-primary;
  form {
	  margin-top: 60px;
  }
  .cols {
	  span {
		  display: block;
	  }
  }
  @include at-breakpoint ('small') {
	  .cols {
		  span {
			  display: inline-block;
		  }
	  }
  }
  
  @include at-breakpoint ('large') {
    .cols {
	  
      margin-top: 0px;
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      -webkit-column-gap: 4vw;
      -moz-column-gap: 4vw;
      column-gap: 4vw;
      .col-part-1 {
	  	min-height: 280px;
      }
      .col-part-2 {
	   	padding-top: 60px;
	  	min-height: 200px;
      }
    }
  }
  b {
	  min-width: 130px;
	  display: inline-block;
  }
}