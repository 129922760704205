#participer {
  min-height: 450px;
  position: relative;
  padding: 2em 0;
  background: $color-tertiary;
  form {
	  margin-top: 60px;
	  button {
		float: right;
	  }
  }
  .deadline {
	  margin-top: 0px;
	  @include at-breakpoint('medium') {
	  	margin-top: 35px;
  	  }
  }
  
}
