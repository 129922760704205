@charset "UTF-8";
body {
  margin: 0;
  padding: 0;
}

section {
  overflow: hidden;
}

.small-container {
  width: 86%;
  margin: 0 auto;
}

.only-medium {
  display: none;
}

.only-mobile {
  display: block;
}

@media (min-width: 62rem) {
  .only-mobile {
    display: none;
  }
  .only-medium {
    display: block;
  }
  .small-container {
    width: 100%;
  }
  .container {
    max-width: 1200px;
    width: 86%;
    margin: 0 auto;
  }
  .left,
  .right {
    width: 45%;
  }
  .left {
    float: left;
    padding-right: 5%;
  }
  .right {
    float: right;
    padding-left: 5%;
  }
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-ExtraLight.otf") format("opentype"), url("../fonts/Montserrat-ExtraLight.woff") format("woff"), url("../fonts/Montserrat-ExtraLight.ttf") format("truetype"), url("../fonts/Montserrat-ExtraLight.svg#Montserrat-ExtraLight") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-SemiBold.otf") format("opentype"), url("../fonts/Montserrat-SemiBold.woff") format("woff"), url("../fonts/Montserrat-SemiBold.ttf") format("truetype"), url("../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Regular.otf") format("opentype"), url("../fonts/Montserrat-Regular.woff") format("woff"), url("../fonts/Montserrat-Regular.ttf") format("truetype"), url("../fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: 500;
  font-style: normal;
}

/*
 * Typography
 */
body {
  font-family: 'Montserrat', sans-serif;
  color: #292928;
}

a {
  outline: 0;
  color: black;
}

p {
  line-height: 1.5;
  font-weight: 100;
}

ul li {
  font-weight: 100;
  line-height: 1.5;
  padding-left: 10px;
}

b,
strong {
  font-weight: 500;
}

h1,
h2,
h3,
h4 {
  text-transform: uppercase;
}

li {
  line-height: 1.5;
}

h2 {
  font-weight: 500;
  font-size: 1.7em;
}

.centered {
  text-align: center;
}

.light {
  font-weight: 100;
}

.red {
  color: #2995A8 !important;
}

section {
  background: white;
}

.edge--bottom {
  position: relative;
  z-index: 1;
}

.edge--bottom:after {
  background: inherit;
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}

.edge--bottom:after {
  bottom: 0;
  transform: skewY(-1.5deg);
  transform-origin: 100%;
}

.edge--bottom--reverse {
  position: relative;
  z-index: 1;
}

.edge--bottom--reverse:after {
  background: inherit;
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}

.edge--bottom--reverse:after {
  bottom: 0;
  transform: skewY(1.5deg);
  transform-origin: 0 100%;
}

.edge--top {
  position: relative;
  z-index: 1;
}

.edge--top:before {
  background: inherit;
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}

.edge--top:before {
  top: 0;
  transform: skewY(1.5deg);
  transform-origin: 100% 0;
}

.edge--top--reverse {
  position: relative;
  z-index: 1;
}

.edge--top--reverse:before {
  background: inherit;
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}

.edge--top--reverse:before {
  top: 0;
  transform: skewY(-1.5deg);
  transform-origin: 0 0;
}

.edge--both {
  position: relative;
  z-index: 1;
}

.edge--both:before, .edge--both:after {
  background: inherit;
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}

.edge--both:before {
  top: 0;
  transform: skewY(1.5deg);
  transform-origin: 100% 0;
}

.edge--both:after {
  bottom: 0;
  transform: skewY(-1.5deg);
  transform-origin: 100%;
}

.edge--both--reverse {
  position: relative;
  z-index: 1;
}

.edge--both--reverse:before, .edge--both--reverse:after {
  background: inherit;
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}

.edge--both--reverse:before {
  top: 0;
  transform: skewY(-1.5deg);
  transform-origin: 0 0;
}

.edge--both--reverse:after {
  bottom: 0;
  transform: skewY(1.5deg);
  transform-origin: 0 0;
}

.block {
  color: #fff;
  font-family: 'Fira Sans', sans-serif;
  margin: 0 0 200px;
  padding: 20% 20px;
  text-align: center;
}

button, .button {
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  border: 2px solid #D63C96;
  color: #D63C96;
  font-weight: bold;
  padding: 0.5em;
  font-size: 1.2em;
}

@media screen and (max-width: 960px) {
  button, .button {
    font-size: 1.1em;
  }
}

@media screen and (max-width: 480px) {
  button, .button {
    font-size: 1em;
  }
}

button a, .button a {
  text-decoration: none;
  display: block;
}

button:active, .button:active {
  opacity: 1;
}

.bg-primary-color {
  background-color: #D63C96;
}

.negative * {
  border-color: white;
  color: white;
}

.negative * input, .negative * textarea {
  color: black;
  border: black;
}

#language {
  position: absolute;
  margin: 10px;
  right: 0px;
  z-index: 100;
}

form .form-row {
  clear: both;
  margin: 2vh 0;
}

form .fifty {
  overflow: hidden;
}

form .fifty label {
  width: 50%;
  float: left;
  box-sizing: border-box;
}

form .align-right {
  text-align: right;
}

form .label-text {
  float: left;
  width: auto;
  margin-right: 5%;
}

@media (min-width: 37.5rem) {
  form .label-text {
    width: 20%;
  }
}

form input,
form textarea {
  border: 2px solid #555;
  padding: 0.5em;
  box-sizing: border-box;
  width: 100%;
}

@media (min-width: 37.5rem) {
  form input,
  form textarea {
    width: 75%;
  }
}

form input:focus,
form textarea:focus {
  border-color: #2995A8;
}

.tabs {
  margin: 1em 0;
  padding: 0;
}

.tabs .tab {
  display: inline-block;
}

.tabs .tab .tablink {
  display: block;
  font-size: 1.2em;
  padding: 0.5em;
  font-weight: bold;
  text-decoration: none;
}

body {
  background: url("../images/home_bg.jpg") no-repeat top center fixed;
  background-size: auto 150vh;
  background-color: #2995A8;
}

#landing {
  background: transparent;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

#landing .logo {
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 37.5rem) {
  #landing .logo {
    width: 350px;
  }
}

@media (min-width: 78rem) {
  #landing .logo {
    width: 400px;
  }
}

#landing .video-background {
  display: none;
}

#landing .triangle-topleft {
  display: block;
  position: absolute;
  z-index: 9;
  background: url("../images/site-banner-s.png") no-repeat;
  min-width: 330px;
  min-height: 165px;
}

@media (min-width: 37.5rem) {
  #landing .triangle-topleft {
    background: url("../images/site-banner-s.png") no-repeat;
    min-width: 330px;
    min-height: 165px;
  }
}

@media (min-width: 62rem) and (orientation: landscape) {
  #landing .triangle-topleft {
    background: url("../images/site-banner-s.png") no-repeat;
    min-width: 330px;
    min-height: 165px;
  }
}

@media (min-width: 62rem) and (orientation: portrait) {
  #landing .triangle-topleft {
    background: url("../images/site-banner-l.png") no-repeat;
    min-width: 660px;
    min-height: 330px;
  }
}

@media (min-width: 78rem) {
  #landing .triangle-topleft {
    background: url("../images/site-banner-l.png") no-repeat;
    min-width: 660px;
    min-height: 330px;
  }
}

#landing .triangle-topleft-en {
  display: block;
  position: absolute;
  z-index: 9;
  background: url("../images/appelprojets-small_en.png") no-repeat;
  min-width: 330px;
  min-height: 165px;
}

@media (min-width: 37.5rem) {
  #landing .triangle-topleft-en {
    background: url("../images/appelprojets-small_en.png") no-repeat;
    min-width: 330px;
    min-height: 165px;
  }
}

@media (min-width: 62rem) and (orientation: landscape) {
  #landing .triangle-topleft-en {
    background: url("../images/appelprojets-small_en.png") no-repeat;
    min-width: 330px;
    min-height: 165px;
  }
}

@media (min-width: 62rem) and (orientation: portrait) {
  #landing .triangle-topleft-en {
    background: url("../images/appelprojets_en.png") no-repeat;
    min-width: 660px;
    min-height: 330px;
  }
}

@media (min-width: 78rem) {
  #landing .triangle-topleft-en {
    background: url("../images/appelprojets_en.png") no-repeat;
    min-width: 660px;
    min-height: 330px;
  }
}

@media (min-width: 62rem) {
  #landing .video-background {
    display: block;
    background: #292928;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
  }
  #landing .video-background iframe,
  #landing .video-foreground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

@media (min-width: 62rem) and (min-aspect-ratio: 16 / 9) {
  #landing .video-foreground {
    height: 200%;
    top: -50%;
  }
}

@media (min-width: 62rem) and (max-aspect-ratio: 16 / 9) {
  #landing .video-foreground {
    width: 200%;
    left: -50%;
  }
}

#landing .inner {
  height: 100%;
  overflow: hidden;
  width: 100%;
  background: rgba(41, 41, 40, 0.5);
}

#landing #youtube {
  display: none;
}

#landing .bg-tilted {
  width: 100%;
  text-align: right;
  overflow: hidden;
  z-index: 3;
}

#landing .bg-tilted:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  left: 0;
  height: 0;
  top: 0;
  border-top: 300px solid #D63C96;
  border-right: 400px solid transparent;
  z-index: 0;
}

@media screen and (max-width: 960px) {
  #landing .bg-tilted:after {
    border-top: 200px solid #D63C96;
    border-right: 300px solid transparent;
  }
}

@media screen and (max-width: 480px) {
  #landing .bg-tilted:after {
    border-top: 200px solid #D63C96;
  }
}

#landing .bg-tilted button {
  z-index: 3;
  position: absolute;
  right: 3vw;
  bottom: 3vw;
}

@media (min-width: 37.5rem) {
  #landing .bg-tilted button {
    right: 6vw;
    bottom: 6vw;
  }
}

@media (min-width: 62rem) {
  #landing .bg-tilted button {
    right: 5vw;
    bottom: 5vw;
  }
}

@media (min-width: 62rem) and (orientation: landscape) {
  #landing .bg-tilted button {
    right: 3vw;
    bottom: 3vw;
  }
}

#landingIntro {
  width: 100%;
  z-index: 3;
  position: relative;
  margin-top: 160px;
}

@media (orientation: landscape) {
  #landingIntro button {
    float: left;
  }
  #landingIntro #main-title {
    float: left;
    width: 70%;
    margin-right: 0px;
  }
  #landingIntro h1#main-title {
    font-size: 25px;
  }
}

@media (min-width: 62rem) {
  #landingIntro button {
    float: none;
  }
  #landingIntro #main-title {
    float: none;
    width: 100%;
  }
}

#landingIntro h2.light {
  margin-bottom: 0px;
}

#landingIntro h3.light {
  margin-top: 5px;
  font-size: 0.8em;
}

@media (min-width: 37.5rem) {
  #landingIntro h3.light {
    font-size: 1.2em;
  }
}

@media (min-width: 62rem) {
  #landingIntro h3.light {
    font-size: 1.2em;
  }
}

@media (min-width: 78rem) {
  #landingIntro h3.light {
    font-size: 1.4em;
  }
}

#landingIntro h1.huge {
  line-height: 1.4;
  margin: 0;
  font-size: 1.3em;
  text-shadow: 0 0 0.3em #292928;
  margin-bottom: 30px;
}

@media (min-width: 37.5rem) {
  #landingIntro h1.huge {
    font-size: 2.4em;
  }
}

@media (min-width: 62rem) {
  #landingIntro h1.huge {
    font-size: 2.6em;
  }
}

@media (min-width: 78rem) {
  #landingIntro h1.huge {
    font-size: 3em;
  }
}

#landingIntro h2 {
  font-size: 1em;
}

@media (min-width: 37.5rem) {
  #landingIntro h2 {
    font-size: 1.4em;
  }
}

@media (orientation: landscape) {
  #landingIntro {
    width: 100%;
  }
}

@media (min-width: 37.5rem) {
  #landingIntro {
    margin-top: 40vh;
  }
  #landingIntro h2 {
    font-size: 2.2em;
  }
}

@media (min-width: 62rem) {
  #landingIntro {
    margin-top: 40vh;
  }
  #landingIntro h2 {
    font-size: 2em;
  }
}

#intro {
  position: relative;
  padding: 0;
  overflow: hidden;
}

#intro .intro-part.part-1 {
  background: #2995A8;
  position: relative;
  padding-top: 2em;
  margin-bottom: 3em;
}

#intro .intro-part.part-1 p {
  padding: 10px 0;
}

#intro .intro-part.part-1 p.paraA {
  font-size: 1.5em;
  text-transform: uppercase;
}

@media (min-width: 62rem) {
  #intro .intro-part.part-1 p.paraA {
    font-size: 2.2em;
  }
}

@media (min-width: 78rem) {
  #intro .intro-part.part-1 p.paraA {
    font-size: 2.7em;
  }
}

#intro .intro-part.part-1 p.paraB {
  font-size: 1.2em;
  width: 90%;
  padding-bottom: 40px;
}

@media (min-width: 62rem) {
  #intro .intro-part.part-1 p.paraB {
    width: 70%;
    font-size: 1.4em;
  }
}

@media (min-width: 78rem) {
  #intro .intro-part.part-1 p.paraB {
    width: 800px;
    font-size: 1.4em;
  }
}

#intro .intro-part.part-1:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3em 0 0 100vw;
  border-color: #2995A8 transparent transparent transparent;
  margin-bottom: 3em;
  overflow: hidden;
}

@media (min-width: 78rem) {
  #intro .intro-part.part-1:after {
    border-top-width: 7em;
  }
}

#intro .cols {
  padding-top: 30px;
  padding-bottom: 40px;
}

@media (min-width: 78rem) {
  #intro .col-part-2 {
    padding-top: 55px;
  }
  #intro .cols {
    margin-top: 80px;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 2vw;
    -moz-column-gap: 2vw;
    column-gap: 4vw;
  }
  #intro .cols p {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    margin-top: 0;
  }
}

#rules {
  background-color: #2995A8;
  padding: 1em 0;
  margin-top: 5em;
  z-index: 32;
  position: relative;
}

#rules:after, #rules:before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
}

#rules:before {
  top: -3em;
  border-style: solid;
  border-width: 0 0 3em 100vw;
  border-color: transparent transparent #2995A8 transparent;
}

#rules:after {
  bottom: -3em;
  border-style: solid;
  border-width: 3em 0 0 100vw;
  border-color: #2995A8 transparent transparent transparent;
}

@media (min-width: 62rem) {
  #rules {
    background: transparent;
    position: absolute;
    display: block;
    width: 0;
    left: 0;
    height: 0;
    bottom: 0;
    border-bottom: 100vh solid #2995A8;
    border-right: 80vw solid transparent;
    z-index: -1;
  }
  #rules:after, #rules:before {
    display: none;
  }
  #rules .rules-inner {
    width: 100vw;
    position: absolute;
    margin-top: 50vh;
  }
}

@media screen and (min-width: 62rem) and (max-height: 760px) {
  #rules .rules-inner {
    margin-top: 40vh;
  }
}

@media (min-width: 62rem) {
  #rules .left {
    width: 50%;
    margin: 0;
    padding: 0;
  }
}

#details {
  position: relative;
  background: rgba(41, 41, 40, 0.8);
}

#details .inner {
  padding: 5vh 0;
  overflow: hidden;
}

#details .tab-content {
  padding: 0;
  position: relative;
  margin-bottom: 3em;
}

#details .tab-content.one {
  background: #D63C96;
}

#details .tab-content.one:before {
  border-color: transparent transparent #D63C96 transparent;
}

#details .tab-content.one:after {
  border-color: #D63C96 transparent transparent transparent;
}

#details .tab-content.two {
  background: #2995A8;
}

#details .tab-content.two:before {
  border-color: transparent transparent #2995A8 transparent;
}

#details .tab-content.two:after {
  border-color: #2995A8 transparent transparent transparent;
}

#details .tab-content .container {
  min-height: 300px;
  padding: 5vh 0;
}

#details .tab-content:after, #details .tab-content:before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
}

#details .tab-content:before {
  top: -3em;
  border-style: solid;
  border-width: 0 0 3em 100vw;
  margin-bottom: 3em;
  overflow: hidden;
}

#details .tab-content:after {
  border-style: solid;
  border-width: 3em 0 0 100vw;
  overflow: hidden;
}

#details .tabs {
  position: relative;
  margin-bottom: 10vh;
  z-index: 10;
}

#details .tabs .tab .tablink {
  padding: 0.3em;
  font-size: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  border: 2px solid white;
  background-color: #292928;
  margin-left: 2vw;
  margin-right: 2vw;
  color: white;
}

@media (min-width: 37.5rem) {
  #details .tabs .tab .tablink {
    font-size: 1.2em;
    padding: 0.5em 1em;
    margin-top: 0;
    margin-bottom: 0;
  }
}

#details .tabs .tab .tablink.active.one {
  background-color: #D63C96;
}

#details .tabs .tab .tablink.active.two {
  background-color: #2995A8;
}

#details .wireframe {
  min-height: 270px;
  background: url("../images/wireframe_3.png") no-repeat center center/contain;
}

@media (min-width: 37.5rem) {
  #details .wireframe {
    min-height: 330px;
  }
}

@media (min-width: 62rem) {
  #details {
    padding: 0;
  }
  #details .wireframe {
    float: right;
    width: 50%;
  }
}

@media (min-width: 78rem) {
  #details .details-part {
    width: 45vw;
  }
}

#details .rules {
  font-size: 0.9em;
  overflow: hidden;
}

#details .region-buttons .inner {
  padding-top: 0;
}

#details .region-buttons .inner button {
  margin-bottom: 1em;
  display: inline-block;
  margin-right: 1em;
  background: #2995A8;
  border-color: white;
  color: white;
}

#details .region-buttons .inner .button-participate button {
  background: #292928;
}

#participer {
  min-height: 450px;
  position: relative;
  padding: 2em 0;
  background: #292928;
}

#participer form {
  margin-top: 60px;
}

#participer form button {
  float: right;
}

#participer .deadline {
  margin-top: 0px;
}

@media (min-width: 62rem) {
  #participer .deadline {
    margin-top: 35px;
  }
}

/*
 *  A PROPOS
 */
#a-propos {
  position: relative;
  z-index: 1;
  background: white;
}

@media (min-width: 62rem) {
  #a-propos {
    height: 450px;
  }
  #a-propos .container {
    position: relative;
    height: 100%;
  }
  #a-propos a.mei-logo {
    top: -1.8em;
    right: -1em;
  }
  #a-propos:before {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    left: 0;
    height: 0;
    bottom: 0;
    border-top: 120vh solid #292928;
    border-right: 80vw solid transparent;
    z-index: 0;
    top: -10vh;
  }
}

#a-propos > div {
  overflow: hidden;
}

#a-propos #qui-sommes-nous {
  position: relative;
  background: #D63C96;
}

#a-propos #qui-sommes-nous button {
  margin-top: 10px;
}

#a-propos #qui-sommes-nous a {
  text-decoration: none;
}

#a-propos #qui-sommes-nous p a {
  text-decoration: underline;
}

@media (min-width: 62rem) {
  #a-propos #qui-sommes-nous {
    width: 50%;
  }
  #a-propos #qui-sommes-nous .para-1 {
    width: 100%;
  }
  #a-propos #qui-sommes-nous .para-2 {
    width: 85%;
  }
}

#a-propos #qui-sommes-nous .small-container {
  padding-bottom: 30px;
}

#a-propos #qui-sommes-nous   .small-container {
  padding-bottom: 0px;
}

#a-propos #qui-sommes-nous a.mei-logo {
  position: absolute;
  right: 1em;
  top: 1em;
  width: 55px;
  height: 55px;
  background: url("../images/mei.png") no-repeat center center/contain;
  display: block;
}

#a-propos #qui-sommes-nous h2 {
  padding-top: 1em;
  padding-right: 60px;
  padding-bottom: 1em;
  margin-bottom: 0;
}

#a-propos #qui-sommes-nous .small-container {
  overflow: hidden;
}

#a-propos #qui-sommes-nous:after {
  content: "";
  position: absolute;
  bottom: -3em;
  border-style: solid;
  border-width: 3em 100vw 0 0;
  border-color: #D63C96 transparent transparent transparent;
}

@media (min-width: 62rem) {
  #a-propos #qui-sommes-nous {
    background: none;
  }
  #a-propos #qui-sommes-nous h2 {
    overflow: hidden;
    position: relative;
    margin-bottom: 2vh;
    padding-right: 111px;
  }
  #a-propos #qui-sommes-nous:after {
    display: none;
  }
}

#a-propos #contact {
  text-align: center;
  margin-top: 12vh;
}

#a-propos #contact .section {
  margin-left: 0px;
  margin-top: 60px;
  margin-bottom: 60px;
}

#a-propos #contact #en-edit {
  margin-top: 60px;
}

#a-propos #contact h2 {
  margin-bottom: 0px;
  text-align: center;
}

#a-propos #contact p {
  text-align: center;
  margin-top: 2px;
  margin-bottom: 5px;
}

#a-propos #contact .lien {
  margin-top: 0px;
  margin-bottom: 20px;
}

@media (min-width: 62rem) {
  #a-propos #contact {
    position: absolute;
    margin-top: 0px;
    right: 0;
    text-align: right;
  }
  #a-propos #contact .section {
    margin-left: 40px;
  }
  #a-propos #contact #en-edit {
    margin-top: 90px;
  }
  #a-propos #contact h2 {
    text-align: right;
  }
  #a-propos #contact p {
    text-align: right;
  }
  #a-propos #contact .socials {
    display: inline-block;
    text-align: right;
    overflow: hidden;
  }
  #a-propos #contact .socials .social {
    float: right;
    margin-left: 15px;
  }
  #a-propos #contact .lien {
    margin-top: 10px;
    margin-bottom: 0px;
  }
}

#a-propos #contact > p {
  width: 75%;
  text-align: left;
  float: right;
}

#a-propos #gtm_index_contact {
  float: right;
}

#a-propos .socials {
  padding-top: 7px;
  width: 210px;
  display: inline-block;
  text-align: center;
  overflow: hidden;
}

#a-propos .socials .social {
  display: inline-block;
  margin-left: 15px;
}

#apercu {
  padding: 4em 0;
}

#apercu .negative {
  color: white;
}

#apercu .small {
  font-size: 0.7em;
}

#apercu .tab-content {
  padding: 0;
  margin-bottom: 3em;
  position: relative;
}

#apercu .tab-content.one {
  background: #D63C96;
}

#apercu .tab-content.one:before {
  border-color: transparent transparent #D63C96 transparent;
}

#apercu .tab-content.one:after {
  border-color: #D63C96 transparent transparent transparent;
}

#apercu .tab-content.two {
  background: #2995A8;
}

#apercu .tab-content.two:before {
  border-color: transparent transparent #2995A8 transparent;
}

#apercu .tab-content.two:after {
  border-color: #2995A8 transparent transparent transparent;
}

#apercu .tab-content .container {
  min-height: 300px;
  padding: 5vh 0;
}

#apercu .tab-content:after, #apercu .tab-content:before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
}

#apercu .tab-content:before {
  top: -3em;
  border-style: solid;
  border-width: 0 0 3em 100vw;
  margin-bottom: 3em;
  overflow: hidden;
}

#apercu .tab-content:after {
  border-style: solid;
  border-width: 3em 0 0 100vw;
  overflow: hidden;
}

#apercu .tabs {
  position: relative;
  margin-bottom: 4em;
  z-index: 10;
}

#apercu .tabs .tab .tablink {
  padding: 0.3em;
  font-size: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  border: 2px solid white;
  background-color: #292928;
  margin-left: 2vw;
  margin-right: 2vw;
  color: white;
}

@media (min-width: 37.5rem) {
  #apercu .tabs .tab .tablink {
    font-size: 1.2em;
    padding: 0.5em 1em;
    margin-top: 0;
    margin-bottom: 0;
  }
}

#apercu .tabs .tab .tablink.active.one {
  background-color: #D63C96;
}

#apercu .tabs .tab .tablink.active.two {
  background-color: #2995A8;
}

#apercu #schedule {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  margin-bottom: 50px;
}

#apercu #schedule .schedule-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  height: 140px;
}

#apercu #schedule .schedule-item .time {
  width: 20%;
}

@media (min-width: 37.5rem) {
  #apercu #schedule .schedule-item .time {
    width: 20%;
  }
  #apercu #schedule .schedule-item .time div {
    width: 60px;
  }
}

#apercu #schedule .schedule-item .time div {
  padding: 10px 5px;
  width: 50px;
  text-align: center;
  background-color: white;
}

#apercu #schedule .schedule-item .icon {
  width: 30%;
}

@media (min-width: 37.5rem) {
  #apercu #schedule .schedule-item .icon {
    width: 20%;
  }
  #apercu #schedule .schedule-item .icon img {
    width: 140px;
  }
}

#apercu #schedule .schedule-item .icon img {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

#apercu #schedule .schedule-item .data {
  width: 50%;
}

@media (min-width: 37.5rem) {
  #apercu #schedule .schedule-item .data {
    width: 60%;
  }
}

#apercu #schedule .schedule-item .data p {
  margin: 5px 0;
}

#apercu .box {
  position: relative;
  margin: 4em 0;
  background: #D63C96;
}

#apercu .box:before {
  border-color: transparent transparent #D63C96 transparent;
}

#apercu .box:after {
  border-color: #D63C96 transparent transparent transparent;
}

#apercu .box:after, #apercu .box:before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
}

#apercu .box:before {
  top: -3em;
  border-style: solid;
  border-width: 0 0 3em 100vw;
  margin-bottom: 3em;
  overflow: hidden;
}

#apercu .box:after {
  border-style: solid;
  border-width: 3em 0 0 100vw;
  overflow: hidden;
}

#apercu .center {
  text-align: center;
  width: 100%;
}

#apercu .container {
  padding: 30px 0;
}

#apercu .flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

#apercu .flex-container .flex-content {
  position: relative;
  margin: 5px;
  padding: 0;
  width: 250px;
  height: 300px;
  background-color: white;
}

#apercu .flex-container .flex-content .desc {
  padding: 5px 10px;
}

#apercu .flex-container .flex-content .contributor-social {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0 5px 0 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

#apercu .flex-container .flex-content .contributor-social .social {
  width: 15px;
  margin: 5px;
  padding: 0;
}

#apercu .flex-container .flex-content h4 {
  margin: 5px 0;
}

#apercu .flex-container .flex-content p {
  margin: 0;
}

#apercu .flex-container .flex-content .img-crop {
  margin: 0px;
  width: 100%;
  height: 190px;
  overflow: hidden;
}

#apercu .flex-container .flex-content .img-crop img {
  width: 100%;
}

#kit-intro {
  background: white;
  position: relative;
  z-index: 1;
  margin: 0 0 0px;
  padding: 50px 20px;
  overflow: visible;
  position: relative;
  z-index: 1;
}

#kit-intro:after {
  background: inherit;
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}

#kit-intro:after {
  bottom: 0;
  transform: skewY(1.5deg);
  transform-origin: 0 100%;
}

#kit-projection {
  position: relative;
  padding: 20px 0;
  background: #D63C96;
}

#kit-projection form {
  margin-top: 60px;
}

#kit-projection .cols span {
  display: block;
}

@media (min-width: 37.5rem) {
  #kit-projection .cols span {
    display: inline-block;
  }
}

@media (min-width: 78rem) {
  #kit-projection .cols {
    margin-top: 0px;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 4vw;
    -moz-column-gap: 4vw;
    column-gap: 4vw;
  }
  #kit-projection .cols .col-part-1 {
    min-height: 280px;
  }
  #kit-projection .cols .col-part-2 {
    padding-top: 60px;
    min-height: 200px;
  }
}

#kit-projection b {
  min-width: 130px;
  display: inline-block;
}

#kit-specification {
  background: #292928;
  position: relative;
  z-index: 1;
  margin: 0 0 0px;
  padding: 30px 0;
  overflow: visible;
  position: relative;
  z-index: 1;
}

#kit-specification:before, #kit-specification:after {
  background: inherit;
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}

#kit-specification:before {
  top: 0;
  transform: skewY(-1.5deg);
  transform-origin: 0 0;
}

#kit-specification:after {
  bottom: 0;
  transform: skewY(1.5deg);
  transform-origin: 0 0;
}

#kit-specification .cols {
  padding-top: 30px;
  padding-bottom: 40px;
}

#kit-conseil {
  position: relative;
  padding: 80px 0;
  background: white;
}

#kit-conseil .thumb {
  width: 100%;
  display: inline-block;
}

@media (min-width: 62rem) {
  #kit-conseil .thumb {
    width: 49%;
  }
}

@media (min-width: 37.5rem) {
  #kit-conseil .thumb {
    width: 49%;
  }
}

#kit-scene {
  background: #D63C96;
  position: relative;
  z-index: 1;
  margin: 0 0 0px;
  padding: 10px 0 80px 0;
  overflow: visible;
  position: relative;
  z-index: 1;
}

#kit-scene:before {
  background: inherit;
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}

#kit-scene:before {
  top: 0;
  transform: skewY(1.5deg);
  transform-origin: 100% 0;
}

#kit-scene .logos {
  display: inline-block;
}

#kit-scene .logos img {
  height: 30px;
  margin-right: 20px;
}

#kit-scene .logos span {
  color: #292928;
  vertical-align: super;
  margin-right: 10px;
}

#kit-scene .logos .button {
  display: inline-block;
  align-items: flex-start;
  text-align: center;
  box-sizing: border-box;
  margin: 10px 20px;
  border-color: #292928;
  background-color: white;
  font-weight: bold;
  padding: 0.5em;
  font-size: 1.2em;
  text-decoration: none;
}

#kit-modele {
  background: #2995A8;
  position: relative;
  z-index: 1;
  margin: 0 0 0px;
  padding: 10px 0 80px 0;
  overflow: visible;
  position: relative;
  z-index: 1;
}

#kit-modele:before {
  background: inherit;
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}

#kit-modele:before {
  top: 0;
  transform: skewY(1.5deg);
  transform-origin: 100% 0;
}

#kit-modele .section p {
  width: 100%;
  display: inline-block;
}

#kit-modele .section .button-container {
  display: inline-block;
  width: 100%;
  height: 42px;
  position: relative;
  top: 0px;
}

#kit-modele .section .button-container button {
  position: absolute;
}

@media (min-width: 62rem) {
  #kit-modele .section p {
    margin-left: 1%;
    width: 69%;
  }
  #kit-modele .section .button-container {
    width: 29%;
  }
}

body.kit {
  background: url("../images/NG16-GOT_48 CO.jpg") no-repeat top center fixed;
  background-size: auto 180vh;
}

#kit-landing {
  background: transparent;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

#kit-landing #landingIntro {
  margin-top: 170px;
}

@media (orientation: landscape) {
  #kit-landing #landingIntro {
    margin-top: 160px;
  }
  #kit-landing #landingIntro h3 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media (min-width: 62rem) {
  #kit-landing #landingIntro {
    margin-top: 30vh;
  }
}

#kit-landing .inner {
  height: 100%;
  overflow: hidden;
  width: 100%;
  background: rgba(41, 41, 40, 0.5);
}

#kit-landing .triangle-topleft {
  display: block;
  position: absolute;
  z-index: 9;
  background: url("../images/appelprojets-small.png") no-repeat;
  min-width: 330px;
  min-height: 165px;
}

@media (min-width: 37.5rem) {
  #kit-landing .triangle-topleft {
    background: url("../images/appelprojets-small.png") no-repeat;
    min-width: 330px;
    min-height: 165px;
  }
}

@media (min-width: 62rem) and (orientation: landscape) {
  #kit-landing .triangle-topleft {
    background: url("../images/appelprojets-small.png") no-repeat;
    min-width: 330px;
    min-height: 165px;
  }
}

@media (min-width: 62rem) and (orientation: portrait) {
  #kit-landing .triangle-topleft {
    background: url("../images/appelprojets.png") no-repeat;
    min-width: 660px;
    min-height: 330px;
  }
}

@media (min-width: 78rem) {
  #kit-landing .triangle-topleft {
    background: url("../images/appelprojets.png") no-repeat;
    min-width: 660px;
    min-height: 330px;
  }
}

#kit-landing .triangle-topleft-en {
  display: block;
  position: absolute;
  z-index: 9;
  background: url("../images/appelprojets-small_en.png") no-repeat;
  min-width: 330px;
  min-height: 165px;
}

@media (min-width: 37.5rem) {
  #kit-landing .triangle-topleft-en {
    background: url("../images/appelprojets-small_en.png") no-repeat;
    min-width: 330px;
    min-height: 165px;
  }
}

@media (min-width: 62rem) and (orientation: landscape) {
  #kit-landing .triangle-topleft-en {
    background: url("../images/appelprojets-small_en.png") no-repeat;
    min-width: 330px;
    min-height: 165px;
  }
}

@media (min-width: 62rem) and (orientation: portrait) {
  #kit-landing .triangle-topleft-en {
    background: url("../images/appelprojets_en.png") no-repeat;
    min-width: 660px;
    min-height: 330px;
  }
}

@media (min-width: 78rem) {
  #kit-landing .triangle-topleft-en {
    background: url("../images/appelprojets_en.png") no-repeat;
    min-width: 660px;
    min-height: 330px;
  }
}

#kit-footer {
  background: #292928;
  position: relative;
  z-index: 1;
  margin: 0 0 0px;
  padding: 20px 20px;
  overflow: visible;
  position: relative;
  z-index: 1;
}

#kit-footer:before {
  background: inherit;
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
}

#kit-footer:before {
  top: 0;
  transform: skewY(1.5deg);
  transform-origin: 100% 0;
}

@media (min-width: 78rem) {
  #kit-footer .cols {
    overflow: hidden;
    margin-bottom: 40px;
  }
  #kit-footer .cols .col {
    float: left;
    width: 48%;
    margin-left: 4%;
  }
  #kit-footer .cols .col:first-child {
    margin-left: 0%;
  }
}

#kit-footer .centered {
  margin-bottom: 60px;
}

#kit-footer .socials {
  width: 100%;
  height: 40px;
}

#kit-footer .social {
  float: left;
  margin-right: 15px;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #292928;
  padding: 0.2em 0;
}
