body {
  background: url("../images/home_bg.jpg") no-repeat top center fixed;
  background-size: auto 150vh;
  background-color: $color-secondary;
}
#landing {
  background:transparent;
  height: 100vh;
  position:relative;
  .logo {
    width: 100%;
    margin-bottom: 20px;
    @include at-breakpoint ('small') {
      width: 350px;
    }

    @include at-breakpoint ('large') {
      width: 400px;
    }
  }
  .video-background {
    display: none;
  }
  .triangle-topleft {
  	display: block;
  	position: absolute;
  	z-index: 9;
  	background: url("../images/site-banner-s.png") no-repeat;
  	min-width: 330px;
  	min-height: 165px;
  	@include at-breakpoint ('small') {
  		background: url("../images/site-banner-s.png") no-repeat;
  		min-width: 330px;
  		min-height: 165px;
  	  }
    @include at-breakpoint ('medium') {
    	@media (orientation: landscape) {
    		background: url("../images/site-banner-s.png") no-repeat;
    		min-width: 330px;
    		min-height: 165px;
    	}
    	@media (orientation: portrait) {
    		background: url("../images/site-banner-l.png") no-repeat;
            min-width: 660px;
    		min-height: 330px;
    	}
    }
    @include at-breakpoint ('large') {
  	  background: url("../images/site-banner-l.png") no-repeat;
        min-width: 660px;
        min-height: 330px;
    }
  }

  .triangle-topleft-en {
  	display: block;
  	position: absolute;
  	z-index: 9;
  	background: url("../images/appelprojets-small_en.png") no-repeat;
  	min-width: 330px;
  	min-height: 165px;
  	@include at-breakpoint ('small') {
  		background: url("../images/appelprojets-small_en.png") no-repeat;
  		min-width: 330px;
  		min-height: 165px;
  	  }
    @include at-breakpoint ('medium') {
    	@media (orientation: landscape) {
    		background: url("../images/appelprojets-small_en.png") no-repeat;
    		min-width: 330px;
    		min-height: 165px;
    	}
    	@media (orientation: portrait) {
    		background: url("../images/appelprojets_en.png") no-repeat;
            min-width: 660px;
    		min-height: 330px;
    	}
    }
    @include at-breakpoint ('large') {
  	  background: url("../images/appelprojets_en.png") no-repeat;
        min-width: 660px;
        min-height: 330px;
    }
  }

  @include at-breakpoint ('medium') {
    .video-background {
      display: block;
      background: $color-tertiary;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      overflow: hidden;
    }

    .video-background iframe,
    .video-foreground {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
    @media (min-aspect-ratio: 16/9) {
      .video-foreground {
        height: 200%;
        top: -50%;
      }
    }
    @media (max-aspect-ratio: 16/9) {
      .video-foreground {
        width: 200%;
        left: -50%;
      }
    }
  }
  overflow: hidden;

  .inner {
    height: 100%;
    overflow: hidden;
    width: 100%;
    background: rgba($color-tertiary,0.5);
  }

  #youtube {
    display: none;
  }
  .bg-tilted {
    width: 100%;
    text-align: right;
    overflow: hidden;
    z-index: 3;

    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      left: 0;
      height: 0;
      top: 0;
      border-top: 300px solid $color-primary;
      border-right: 400px solid transparent;
      @media screen and (max-width: 960px) {
        border-top: 200px solid $color-primary;
        border-right: 300px solid transparent;
      }
      @media screen and (max-width: 480px) {
        border-top: 200px solid $color-primary;
      }
      z-index: 0;
    }

    button {
      z-index: 3;
      position: absolute;
      right: 3vw;
      bottom: 3vw;

      @include at-breakpoint ('small') {
        right: 6vw;
        bottom: 6vw;
      }

      @include at-breakpoint ('medium') {
        right: 5vw;
        bottom: 5vw;
        @media (orientation: landscape) {
          right: 3vw;
          bottom: 3vw;
        }
      }
    }
  }
}

#landingIntro {
  width: 100%;
  z-index: 3;
  position: relative;
  margin-top: 160px;
  	@media (orientation: landscape) {
		button {
		  float: left;
		}
		#main-title {
		    float: left;
		    width: 70%;
		    margin-right: 0px;
		}
		h1#main-title {
			font-size: 25px;
		}
	}
  @include at-breakpoint ('medium') {
	button {
	  float: none;
	}
	#main-title {
	    float: none;
	    width: 100%;
	}
  }
  h2.light {
	  margin-bottom: 0px;
  }
  h3.light {
	  margin-top: 5px;
	  font-size: 0.8em;
	    @include at-breakpoint ('small') {
	      font-size: 1.2em;
	    }

	    @include at-breakpoint ('medium') {
	      font-size: 1.2em;
	    }

	    @include at-breakpoint ('large') {
	      font-size: 1.4em;
	    }

  }
  h1.huge {
    line-height: 1.4;
    margin: 0;
    font-size: 1.3em;
    text-shadow: 0 0 0.3em $color-tertiary;
    margin-bottom: 30px;

    @include at-breakpoint ('small') {
      font-size: 2.4em;
    }

    @include at-breakpoint ('medium') {
      font-size: 2.6em;
    }

    @include at-breakpoint ('large') {
      font-size: 3em;
    }
  }

  h2 {
    font-size: 1em;

    @include at-breakpoint ('small') {
      font-size: 1.4em;
    }
  }
  @media (orientation: landscape) {
    width: 100%;
  }

   @include at-breakpoint ('small') {
    //width: 70%;
    margin-top: 40vh;

    h2 {
      font-size: 2.2em;
    }
  }

  @include at-breakpoint ('medium') {
    //width: 70%;
    margin-top: 40vh;

    h2 {
      font-size: 2em;
    }
  }
}
